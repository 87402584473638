
import ApiService from "@/common/api.service";

import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';
import API from "@/common/API";

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const DELETE_PERSONAL_ACCESS_KEY = "deletePersonalAccessKeys";
export const CREATE_PERSONAL_ACCESS_KEY = "createPersonalAccessKeys";
export const LIST_PERSONAL_ACCESS_KEY_SEARCH = "searchPersonalAccessKeys";

// mutation types

export const SET_LIST_PERSONAL_ACCESS_KEY_SEARCH = "setListPersonalAccessKey";
export const SET_ERROR = "setError";


export const state = () => ({
    errors: null,
    list_personal_access_key: [],
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    listPersonalAccessKey:state  =>  state.list_personal_access_key,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_PERSONAL_ACCESS_KEY_SEARCH](state, data) {
        state.list_personal_access_key = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
    async  [LIST_PERSONAL_ACCESS_KEY_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.API_PERSONAL_ACCESS_KEY}/search`, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_PERSONAL_ACCESS_KEY_SEARCH, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    async  [CREATE_PERSONAL_ACCESS_KEY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.API_PERSONAL_ACCESS_KEY, credentials)
                .then((data:any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    async  [DELETE_PERSONAL_ACCESS_KEY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.API_PERSONAL_ACCESS_KEY, credentials)
                .then((data:any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

}
const personalAccessKeyModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default personalAccessKeyModule