<template>
  <div>
    <vue-mathjax :formula="formula"></vue-mathjax>
  </div>
</template>
<script>
import {VueMathjax} from "vue-mathjax-next";

export default {
  props:{
    formula: {
      type: String,
      default: "",
    }
  },
  components: {
    'vue-mathjax': VueMathjax
  },
  name: 'HelloWorld',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  },
  mounted() {
    this.addScript('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS_HTML')
  },
  methods: {
    addScript(url) {
      console.log(url)
      var script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.defer = "defer";
      document.head.appendChild(script);
    },

  }
}
</script>