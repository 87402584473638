<template>
<!--  {{editorValue}}-->
  <editor v-model="editorValue" :init="init" @paste="handlePaste"></editor>
  thêm phép tính
  <div>


    <div class="icon-math">
      <button @click="toggleButton('special')" href="#"><img src="../assets/icon/math/math.svg" alt="icon_ohm" class="mr-3"/></button
      >
      <button @click="toggleButton('normal')" href="#"><img src="../assets/icon/math/pi.svg" alt="icon_pi"/></button>
    </div>
    <div>
    </div>
<!--    <textarea v-model="math" class="w-full border border-[#000000]"></textarea>-->
    <div v-if="isShowFormula" class="pb-5">
      <div class="border-yellow-101 border-2 rounded-[10px] p-5">
        <div class="text-black-101 text-[18px]">
          <div class="formula-preview">
            <p class="formula-preview-title">Xem trước</p>
            <MathjaxPreview :formula="math" class="my-2"></MathjaxPreview>
          </div>
        </div>
        <div class="  grid-cols-10 gap-2 " v-if="buttonType === 'special'">
          <MathButton  class=""
                       v-for="(item,index) in buttons" :key="index"
                       :icon_src="item.icon_src"
                       :icon_alt="item.alt"
                       :icon_type="item.type"
                       @click="getButtonValue(item.value)"
          />
        </div>
        <div class="grid grid-cols-10 gap-4 " v-if="buttonType === 'normal'">
          <NormalMathButton  class="math-button-wrapper"
                              v-for="(item,index) in buttons_normal" :key="index"
                              :character="item.character"
                              @click="getNormalButtonValue(item.character)"
          />
        </div>
        <textarea placeholder="Chỉnh sửa công thức..." class="formula-textarea w-full border border-[#000000] mt-4" v-model="math"
                  cols="30" rows="3"></textarea>
        <button class="border border-[#000000] px-2 py-1 rounded-lg" @click="addFormula">Thêm công thức</button>
      </div>
    </div>
<!--    <button class="border border-[#000000] px-2 py-1 rounded-lg" @click="addFormula">Thêm phép tính</button>-->
  </div>
</template>

<script setup>
import {reactive, ref, watch, toRefs, defineProps, defineEmits} from 'vue';

import tinymce from 'tinymce/tinymce.js';
// import 'tinymce/models/dom'; (TinyMCE 6)
import axios from "axios";// 外觀
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/themes/silver';

// Icon
import 'tinymce/icons/default';

// 用到的外掛
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis.js';
import 'tinymce/plugins/table';
import 'tinymce/plugins/quickbars';

// 語言包
// import 'tinymce-i18n/langs/zh_Hans.js' (TinyMCE 6 的簡體中文)
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/media';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/link';
import 'tinymce/plugins/hr';
// TinyMCE-Vue
import Editor from '@tinymce/tinymce-vue';
import {useStore} from "vuex";
import {UPLOAD_IMG} from "@/store/upload";
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import MathjaxPreview from "@/components/widgets/MathjaxPreview";
import MathButton from "@/components/math/MathButton";
import NormalMathButton from "@/components/math/NormalMathButton";

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

});
const store = useStore()
const math = ref('')
const isShowFormula = ref(false)
const buttonType = ref("")
const buttons = ref([
  {
    icon_src: require('@/assets/icon/math/is-less-than-or-equal-to-mathematical-symbol.svg'),
    alt:'icon-is-less-than-or-equal-to-mathematical-symbol',
    value: "$\\leq$"
  }, {
    icon_src: require('@/assets/icon/math/is-equal-to-or-greater-than-symbol.svg'),
    alt:'icon-is-equal-to-or-greater-than-symbol',
    value: "$\\geq$ "
  }, {
    icon_src: require('@/assets/icon/math/square-root-mathematical-symbol.svg'),
    alt:'icon-square-root-mathematical-symbol',
    value: "$\\sqrt[n]{x}$ "
  }, {
    icon_src: require('@/assets/icon/math/is-not-equal-to-mathematical-symbol.svg'),
    alt:'icon-is-not-equal-to-mathematical-symbol',
    value: "$\\neq$"
  }, {
    icon_src: require('@/assets/icon/math/pi-mathematical-constant-symbol.svg'),
    alt:'icon-pi-mathematical-constant-symbol',
    value: "$\\pi$"
  }, {
    icon_src: require('@/assets/icon/math/integral-mathematical-sign.svg'),
    alt:'icon-integral-mathematical-sign',
    value: "$\\int\\limits^a_b {x} \\, dx$ ",
    type: "medium"
  }, {
    icon_src: require('@/assets/icon/math/limit.svg'),
    alt:'icon-limit',
    value: " $\\lim_{n \\to \\infty} a_n$",
    type: 'big'
  }, {
    icon_src: require('@/assets/icon/math/1.svg'),
    alt:'icon-^',
    value: "$x^{2}$ "
  }, {
    icon_src: require('@/assets/icon/math/alpha.svg'),
    alt:'icon-alpha',
    value: "$\\alpha$ "
  }, {
    icon_src: require('@/assets/icon/math/fraction.svg'),
    alt:'icon-fraction',
    value: "$\\frac{x}{y}$ "
  },
  {
    icon_src: require('@/assets/icon/math/somol.svg'),
    alt:'icon-somol',
    value: "$x_{123}$ "
  }, {
    icon_src: require('@/assets/icon/math/22.svg'),
    alt:'icon-he-phuong-trinh',
    value: "$\\left \\{ {{y=2} \\atop {x=2}} \\right.$ "
  }, {
    icon_src: require('@/assets/icon/math/014-matrix.svg'),
    alt:'icon-matrix',
    value: "$\\left[\\begin{array}{ccc}1&2&3\\\\4&5&6\\\\7&8&9\\end{array}\\right]$ ",
    type: "medium"
  },
])
const buttons_normal = ref([
  {
    'character': '²'
  }, {
    'character': '³'
  }, {
    'character': '√'
  }, {
    'character': '∛'
  }, {
    'character': '·'
  }, {
    'character': '×'
  }, {
    'character': '÷'
  }, {
    'character': '±'
  }, {
    'character': '≈'
  }, {
    'character': '≤'
  }, {
    'character': '≥'
  }, {
    'character': '∈'
  }, {
    'character': '∉'
  }, {
    'character': '∧'
  }, {
    'character': '∨'
  }, {
    'character': '∞'
  }, {
    'character': 'Δ'
  }, {
    'character': 'π'
  }, {
    'character': 'Ф'
  }, {
    'character': 'ω'
  }, {
    'character': '↑'
  }, {
    'character': '↓'
  }, {
    'character': '∵'
  }, {
    'character': '∴'
  }, {
    'character': '↔'
  }, {
    'character': '→'
  }, {
    'character': '←'
  }, {
    'character': '⇵'
  }, {
    'character': '⇅'
  }, {
    'character': '⇄'
  }, {
    'character': '⇆'
  }, {
    'character': '∫'
  }, {
    'character': '∑'
  }, {
    'character': '⊂'
  }, {
    'character': '⊃'
  }, {
    'character': '⊆'
  }, {
    'character': '⊇'
  }, {
    'character': '⊅'
  }, {
    'character': '∀'
  }, {
    'character': '∠'
  }, {
    'character': '∡'
  }, {
    'character': '⊥'
  }, {
    'character': '∪'
  }, {
    'character': '∩'
  }, {
    'character': '∅'
  }, {
    'character': '¬'
  }, {
    'character': '⊕'
  }, {
    'character': '║'
  }, {
    'character': '∦'
  }, {
    'character': '∝'
  }, {
    'character': '㏒'
  }, {
    'character': '㏑'
  },
])

const emit = defineEmits(['update:modelValue']);
const plugins = 'quickbars emoticons table  table advlist lists image media anchor hr link';
const toolbar = ' a11ycheck  | bold italic underline strikethrough | fontsizeselect | forecolor backcolor ' +
    '| alignleft aligncenter alignright alignjustify|bullist numlist |outdent ' +
    'indent blockquote | undo redo | axupimgs | removeformat | table | emoticons | link image media anchor | code'

const init = reactive({
  language: 'en',
  height: 300,
  menubar: false,
  content_css: false,
  skin: false,
  plugins: plugins,
  toolbar: toolbar,
  quickbars_insert_toolbar: false,
  file_picker_types: 'file image media',
  image_caption: true,
  branding: false,
  selector: 'textarea',  // change this value according to your HTML
  images_upload_handler: async function(
      blobInfo,
      success,
      failure
  ) {
    let imageFile = new FormData();
    imageFile.append("files[0]", blobInfo.blob());
    imageFile.append("type", 'exams');

    try {
      const {data} = await store.dispatch(UPLOAD_IMG,imageFile)
      const images  = data.data
      if (images.length> 0){
        const url = images[0].url
        success(url);
      }

    } catch (error) {
      return;
    }
  }
});

const {modelValue} = toRefs(props);
const editorValue = ref(modelValue.value);

watch(modelValue, (newValue) => {
  editorValue.value = newValue;
});

watch(editorValue, (newValue) => {
  emit('update:modelValue', newValue);
});

const retrieveImageFromClipboardAsBlob = (pasteEvent) => {
    if (pasteEvent.clipboardData === false) {
        return false;
    }

    var items = pasteEvent.clipboardData.items;

    if (items === undefined) {
        return false;
    }

    for (var i = 0; i < items.length; i++) {
        // Only paste if image is only choice
        if (items[i].type.indexOf("image") === -1) {
            return false;
        }
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // load image if there is a pasted image
        if (blob !== null) {
            const reader = new FileReader();
            reader.onload = function(e) {
                // console.log('result', e.target.result);
            };
            reader.readAsDataURL(blob);
            return blob;
        }
    }
    return false;
}

const handlePaste = async (e) => {
  let imageBlob = retrieveImageFromClipboardAsBlob(e);
  if (!imageBlob) {
      return;
  }
  e.preventDefault();
  let imageFile = new FormData();
  imageFile.append("files[0]", imageBlob);
  imageFile.append("type", 'exams');

  try {
    const {data} = await store.dispatch(UPLOAD_IMG,imageFile)
    const images  = data.data
    if (images.length> 0){
      const url = images[0].url
      getTinymce().activeEditor.insertContent('<img src="' + url + '" />');
    }
  } catch (error) {
    return;
  }
}

const toggleButton = (type) => {
  // math.value = ''
  if (buttonType.value === type) {
    isShowFormula.value = !isShowFormula.value
  } else {
    isShowFormula.value = true;
    buttonType.value = type;
  }
}

const getButtonValue = (value) => {
  math.value += value;
}

const getNormalButtonValue = (value) => {
  math.value += value;
}

const addFormula = () => {
  editorValue.value += '<p>'+math.value+'</p>'
  math.value = "";
}

</script>
<style lang="scss">
.editor-preview {


  .symbol-math {
    .symbol-math-grid {
      .formula-box {
        .formula-preview {
          @apply p-[10px] text-[18px] min-h-[100px] bg-[#dee0e2] ;
        }

        .formula-preview:empty:before {
          content: attr(data-placeholder);
          color: gray
        }

        .formula-preview-title {
          @apply underline text-[16px] mb-2;
        }
      }

      .virtual-keyboard-container {
        @apply my-3 flex-wrap bg-white flex;
        .math-button-wrapper {
          @apply bg-white mx-[5px] mt-[5px];
        }
      }

      .formula-textarea {
        @apply focus:outline-none border border-[#ccc] w-full text-[18px] p-[10px];
      }

      .formula-textarea::placeholder {
        @apply text-[16px] italic;
      }



    }
  }
}
</style>