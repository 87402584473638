import  { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store  from './store'
import ApiService from './common/api.service'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/tailwind.css'
import './assets/main.scss'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Toaster from '@meforma/vue-toaster';
import './plugins/validate'
import ListQuestion from "@/components/question/ListQuestion.vue";
import CreateTemporaryQuestion from "@/components/question/CreateTemporaryQuestion.vue";
import i18n from './common/i18n'
import {createPinia} from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueMathjax from 'vue-mathjax-next';

ApiService.init();
const app = createApp(App)
app.use(store)
app.use(router)
app.use(Toaster)
app.use(i18n)
app.use(createPinia())

app.use(VueAxios,axios)
app.use(ElementPlus)
app.use(VueMathjax)

app.component('list-question', ListQuestion);
app.component('CreateTemporaryQuestion', CreateTemporaryQuestion);

app.mount('#app')
app.component('EasyDataTable', Vue3EasyDataTable);
