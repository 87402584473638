import {GetterTree, ActionTree, MutationTree, Module} from 'vuex';

// @ts-ignore
import ApiService from "@/common/api.service";

/* eslint-disable @typescript-eslint/no-explicit-any */

export const GET_LIST = '/grade/list';

export const SET_LIST = '/grade/list';

export const state = () => ({
    grades: [] as any,
})

export type RootState = ReturnType<typeof state>

const getters: GetterTree<RootState, RootState> = {
    grades: state => state.grades,
}

const mutations: MutationTree<RootState> = {
    [SET_LIST](state, data) {
        state.grades = data
    },
}

const actions: ActionTree<RootState, RootState> = {
     [GET_LIST]( {commit} ,params) {
         return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST,params).then((data:any)=>{
            if (data.status) {
                commit(SET_LIST, data.data)
            }
            resolve(data);
        })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
}

const testModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default  testModule
