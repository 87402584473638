import API from "@/common/API";
import ApiService from "@/common/api.service";
import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';

// action types
export const STUDENT_LIST = 'studentSearch'
// mutation types
export const SET_STUDENT_SEARCH = 'setStudentSearch'

export const state = ()=>({
    list_student : [] as any
})

type RootState = ReturnType<typeof state>
const getters : GetterTree<RootState, RootState> = {
    list_student: state => state.list_student
}

const mutations: MutationTree<RootState> = {
    [SET_STUDENT_SEARCH](state, data){
        state.list_student = data
    }
}

const actions: ActionTree<RootState, RootState> = {
    [STUDENT_LIST]({commit}, payload){
        return new Promise((resolve, reject)=>{
            ApiService.get(`${API.STUDENT_SEARCH}?page=${payload.page}&limit=${payload.limit}`).then((data : any)=>{
                if(data.data.status){
                    commit(SET_STUDENT_SEARCH, data.data.data)
                }
                resolve(data)
            })
                .catch(({response}) => {
                    reject(response.data)
                })
        })
    }
}
const studentModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default studentModule