// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_EXAM_INTRO_SEARCH = "listExamIntroSearch";
export const GET_LIST_EXAM_INTRO = "listExamIntro";
export const DETAIL_EXAM_INTRO = "dtailExamIntro";
export const DELETE_EXAM_INTRO = "deleteExamIntro";
export const CREATE_EXAM_INTRO = "createExamIntro";
export const UPDATE_EXAM_INTRO = "updateExamIntro";

// mutation types
export const SET_EXAM_INTRO = "examIntro";
export const SET_LIST_EXAM_INTRO = "getListExamIntro";
export const SET_LIST_EXAM_INTRO_SEARCH = "getListExamIntroSearch";
export const SET_ERROR = "setError";

export const state = () => ({
    exam_intro: {},
    exam_intros: [] as any,
    list_exam_intro_search: [] as any,
})

 type RootState = ReturnType<typeof state>
 const getters: GetterTree<RootState, RootState> = {
        examIntro :state  =>  state.exam_intro,
     exam_intros :state  =>  state.exam_intros,
     listExamIntroSearch :state  =>  state.list_exam_intro_search,
}

 const mutations: MutationTree<RootState> = {
    [SET_LIST_EXAM_INTRO](state, data) {
        state.exam_intros = data;
    },
    [SET_LIST_EXAM_INTRO_SEARCH](state, data) {
        state.list_exam_intro_search = data;
    },
    [SET_EXAM_INTRO](state, data) {
        state.exam_intro = data;
    },

}

 const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_EXAM_INTRO]( {commit}) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_EXAM_INTRO,'').then((data:any)=>{
                if (data.data.status) {
                    commit(SET_LIST_EXAM_INTRO, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [LIST_EXAM_INTRO_SEARCH]( {commit}, credentials ) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.API_EXAM_INTRO}/search`, credentials).then((data:any)=>{
                if (data.data.status) {
                    commit(SET_LIST_EXAM_INTRO_SEARCH, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_EXAM_INTRO](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.API_EXAM_INTRO, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_EXAM_INTRO, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_EXAM_INTRO](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.API_EXAM_INTRO, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_EXAM_INTRO](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.API_EXAM_INTRO}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [DELETE_EXAM_INTRO](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.API_EXAM_INTRO, credentials)
                .then((data:any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const examIntroModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default examIntroModule