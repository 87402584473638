import {ActionTree, GetterTree, Module, MutationTree} from "vuex";

/* eslint-disable @typescript-eslint/no-explicit-any */

export const state = () => ({
    name_page1:  null
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    getName_page: state => {
        return state.name_page1;
    }
}

const mutations: MutationTree<RootState> = {
    setName_page: (state, payload) => {
        state.name_page1 = payload;
    }
}

const actions: ActionTree<RootState, RootState> = {}
const headModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default headModule