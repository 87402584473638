
import ApiService from "@/common/api.service";
import API from "@/common/API";

import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_ROLE = "listRole";
export const LIST_ROLE_SEARCH = "searchRole";
export const DELETE_ROLE = "deleteRole";
export const CREATE_ROLE = "createRole";
export const DETAIL_ROLE = "detailRole";
export const UPDATE_ROLE = "updateRole";

// mutation types

export const SET_ROLE = "setRole";
export const SET_LIST_ROLE = "setListRole";
export const SET_LIST_ROLE_SEARCH = "setListRoleSearch";
export const SET_ERROR = "setError";


export const state = () => ({
    errors: null,
    role: {},
    list_role: [],
    list_role_search: [],
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    role:state  =>  state.role,
    listRole:state  =>  state.list_role,
    listRoleSearch:state  =>  state.list_role_search,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_ROLE](state, data) {
        state.role = data;
    },
    [SET_LIST_ROLE](state, data) {
        state.list_role = data;
    },
    [SET_LIST_ROLE_SEARCH](state, data) {
        state.list_role_search = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_ROLE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.API_ROLE, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_ROLE, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [LIST_ROLE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.API_ROLE, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_ROLE, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [LIST_ROLE_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.API_ROLE}/search`, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_ROLE_SEARCH, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_ROLE](context, credentials) {
      return new Promise((resolve, reject) => {
          ApiService.post(API.API_ROLE, credentials)
              .then((data) => {
                  resolve(data)
              }).catch((err) => {
                  reject(err.response.data);
              });
      });
    },
    [UPDATE_ROLE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.API_ROLE}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [DELETE_ROLE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.API_ROLE, credentials)
                .then((data:any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

}
const roleModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default roleModule