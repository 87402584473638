<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Inter;
  /*background-color: #EDF1F5;*/
  
}

</style>
<script>
export default {
}
</script>