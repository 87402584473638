// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import API from "@/common/API";

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_TEMPORARY_EXAM = "listTemporaryExams";
export const GET_LIST_TEMPORARY_EXAM_SEARCH = "listTemporaryExamSearch";
export const GET_DETAIL_TEMPORARY_EXAM = "detailTemporaryExams";
export const UPDATE_TEMPORARY_EXAM = "updateTemporaryExams";
export const ADD_TEMPORARY_EXAM = "addTemporaryExam";
export const DELETE_TEMPORARY_EXAM = "deleteTemporaryExams";
// mutation types
export const SET_LIST_TEMPORARY_EXAM_SEARCH = "getListTemporaryExamSearch";
export const SET_LIST_TEMPORARY_EXAM = "getListTemporaryExams";
export const SET_DETAIL_TEMPORARY_EXAM = "getDetailTemporaryExams";

export const state = () => ({
  list_temporary_exam_search: [] as any,
  temporary_exams: [] as any,
  temporary_exam: {} as any,
  list_questions_temporary: [] as any,
});

type RootState = ReturnType<typeof state>;
const getters: GetterTree<RootState, RootState> = {
  list_temporary_exam_search: (state) => state.list_temporary_exam_search,
  temporary_exams: (state) => state.temporary_exams,
  temporary_exam: (state) => state.temporary_exam,
  list_questions_temporary: (state) => state.list_questions_temporary,
};

const mutations: MutationTree<RootState> = {
  [SET_LIST_TEMPORARY_EXAM_SEARCH](state, data) {
    state.list_temporary_exam_search = data;
  },
  [SET_LIST_TEMPORARY_EXAM](state, data) {
    state.temporary_exams = data;
  },

  [SET_DETAIL_TEMPORARY_EXAM](state, data) {
    state.temporary_exam = data.data;
    state.list_questions_temporary = data.data.questions;
  },
};

const actions: ActionTree<RootState, RootState> = {
  [GET_LIST_TEMPORARY_EXAM_SEARCH]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(API.GET_LIST_TEMPORARY_EXAM_SEARCH, payload)
        .then((data: any) => {
          if (data.data.status) {
            commit(SET_LIST_TEMPORARY_EXAM_SEARCH, data.data.data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_LIST_TEMPORARY_EXAM]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.query(API.GET_LIST_TEMPORARY_EXAM, "")
        .then((data: any) => {
          if (data.data.status) {
            commit(SET_LIST_TEMPORARY_EXAM, data.data.data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DETAIL_TEMPORARY_EXAM]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(API.GET_DETAIL_TEMPORARY_EXAM, payload.id)
        .then((data: any) => {
          if (data.data.status) {
            commit(SET_DETAIL_TEMPORARY_EXAM, data.data.data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [ADD_TEMPORARY_EXAM]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(API.ADD_TEMPORARY_EXAM, payload)
        .then((data: any) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_TEMPORARY_EXAM]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `${API.UPDATE_TEMPORARY_EXAM}/${payload.id}?data=${payload.data}`,
        payload
      )
        .then((data: any) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [DELETE_TEMPORARY_EXAM](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${API.DELETE_TEMPORARY_EXAM}`, credentials)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
};
const temporaryExamModule: Module<any, any> = {
  state,
  mutations,
  actions,
  getters,
};

export default temporaryExamModule;
