
import ApiService from "@/common/api.service";
import API from "@/common/API";

import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_PERMISSION = "listPermission";

// mutation types

export const SET_LIST_PERMISSION = "setListPermission";
export const SET_ERROR = "setError";


export const state = () => ({
    errors: null,
    list_permission: []
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    listPermission:state  =>  state.list_permission
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_PERMISSION](state, data) {
        state.list_permission = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [LIST_PERMISSION](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.API_PERMISSION, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_PERMISSION, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }

}
const roleModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default roleModule