// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const UPLOAD_AUDIO = "upload/audio";
export const UPLOAD_IMG = "upload/image";
export const UPLOAD_IMG_CATEGORY = "upload-img-category/image";
export const UPLOAD_IMG_EXAM = "upload-img-exam/image";
export const UPLOAD_VIDEO = "upload/video";
export const UPLOAD_PDF = "upload/pdf";
// mutation types
export const SET_AUDIO = "setAudio";
export const SET_IMG = "setImg";
export const SET_IMG_CATEGORY = "setImgCategory";
export const SET_IMG_EXAM = "setImgExam";
export const SET_VIDEO = "setVideo";
export const SET_PDF = "setPDF";

export const state = () => ({
    audio: {} as any,
    img: {} as any,
    imgcategory: {} as any,
    imgexam: {} as any,
    video: {} as any,
    pdf: {} as any,
})

 type RootState = ReturnType<typeof state>
 const getters: GetterTree<RootState, RootState> = {
     audio :state  =>  state.audio,
     img :state  =>  state.img,
     imgcategory :state  =>  state.imgcategory,
     imgexam :state  =>  state.imgexam,
     video :state  =>  state.video,
     pdf :state  =>  state.pdf,
}

 const mutations: MutationTree<RootState> = {
    [SET_AUDIO](state, data) {
        state.audio = data;
    },
     [SET_IMG](state, data) {
        state.img = data;
    },
    [SET_IMG_CATEGORY](state, data) {
        state.imgcategory = data;
    },
    [SET_IMG_EXAM](state, data) {
        state.imgexam = data;
    },
     [SET_VIDEO](state, data) {
        state.video = data;
    },
     [SET_PDF](state, data) {
        state.pdf = data;
    },

}

 const actions: ActionTree<RootState, RootState> = {
     [UPLOAD_AUDIO]( {commit}, payload) {
         return new Promise((resolve, reject) => {
             ApiService.post(UPLOAD_AUDIO,payload).then((data:any)=>{
                 if (data.data.status) {
                     commit(SET_AUDIO, data.data.data)
                 }
                 resolve(data);
             })
                 .catch(({ response }) => {
                     reject(response);
                 });
         })
     },
     [UPLOAD_IMG]( {commit}, payload) {
         return new Promise((resolve, reject) => {
             ApiService.post(UPLOAD_IMG,payload).then((data:any)=>{
                 if (data.data.status) {
                     commit(SET_IMG, data.data.data)
                 }
                 resolve(data);
             })
                 .catch(({ response }) => {
                     reject(response);
                 });
         })
     },
     [UPLOAD_IMG_CATEGORY]( {commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPLOAD_IMG_CATEGORY,payload).then((data:any)=>{
                if (data.data.status) {
                    commit(SET_IMG_CATEGORY, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [UPLOAD_IMG_EXAM]( {commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPLOAD_IMG_EXAM,payload).then((data:any)=>{
                if (data.data.status) {
                    commit(SET_IMG_EXAM, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
     [UPLOAD_VIDEO]( {commit}, payload) {
         return new Promise((resolve, reject) => {
             ApiService.post(UPLOAD_VIDEO,payload).then((data:any)=>{
                 if (data.data.status) {
                     commit(SET_VIDEO, data.data.data)
                 }
                 resolve(data);
             })
                 .catch(({ response }) => {
                     reject(response);
                 });
         })
     },
     [UPLOAD_PDF]( {commit}, payload) {
         return new Promise((resolve, reject) => {
             ApiService.post(UPLOAD_PDF,payload).then((data:any)=>{
                 if (data.data.status) {
                     commit(SET_PDF, data.data.data)
                 }
                 resolve(data);
             })
                 .catch(({ response }) => {
                     reject(response);
                 });
         })
     },
}
const uploadModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default uploadModule