
import API from "@/common/API";
import ApiService from "@/common/api.service";

import {GetterTree, ActionTree, MutationTree,Module} from 'vuex';

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_COMPANY = "listCompany";
export const LIST_COMPANY_SEARCH = "searchCompany";
export const DELETE_COMPANY = "deleteCompany";
export const CREATE_COMPANY = "createCompany";
export const DETAIL_COMPANY = "detailCompany";
export const UPDATE_COMPANY = "updateCompany";

// mutation types

export const SET_COMPANY = "setCompany";
export const SET_LIST_COMPANY = "setListCompany";
export const SET_LIST_COMPANY_SEARCH = "setListCompanySearch";
export const SET_DETAIL_COMPANY = "setDetailCompanys";
export const SET_ERROR = "setError";


export const state = () => ({
    errors: null,
    company: {},
    list_company: [],
    list_company_search: [],
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    company:state  =>  state.company,
    listCompany:state  =>  state.list_company,
    listCompanySearch:state  =>  state.list_company_search,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_COMPANY](state, data) {
        state.list_company = data;
    },
    [SET_LIST_COMPANY_SEARCH](state, data) {
        state.list_company_search = data;
    },
    [SET_COMPANY](state, data) {
        state.company = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_COMPANY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.API_COMPANY, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_COMPANY, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [LIST_COMPANY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.API_COMPANY, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_COMPANY, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [LIST_COMPANY_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.API_COMPANY}/search`, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_COMPANY_SEARCH, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_COMPANY](context, credentials) {
      return new Promise((resolve, reject) => {
          ApiService.post(API.API_COMPANY, credentials)
              .then((data) => {
                  resolve(data)
              }).catch((err) => {
                  reject(err.response.data);
              });
      });
    },
    [UPDATE_COMPANY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.API_COMPANY}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [DELETE_COMPANY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.API_COMPANY, credentials)
                .then((data:any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

}
const companyModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default companyModule